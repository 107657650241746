<template>
  <b-container>
    <h1>{{ $t("role.Role") }}: {{ name }}</h1>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-overlay show spinner-variant="primary" no-wrap />
      </template>
      <b-skeleton-wrapper :loading="!roleExists">
        <template #loading>
          <b-card :title="$t('Error')" bg-variant="danger">
            {{$t('role.roleNameNotExisting', {name: name})}}
          </b-card>
        </template>
        <b-card :title="$t('role.Permissions')">
          <permission-view :permissions="permissions" @save="savePermissions" />
        </b-card>
        <b-card :title="$t('role.deleteRole')">
          <b-button variant="danger" @click="deleteRole"
            ><i class="fas fa-trash mr-2"></i
            >{{ $t("role.deleteRoleName", { name: name }) }}</b-button
          >
        </b-card>
      </b-skeleton-wrapper>
    </b-skeleton-wrapper>
  </b-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import PermissionView from "../components/PermissionView.vue";
import Swal from "sweetalert2";

export default {
  components: { PermissionView },
  name: "Role",
  props: {
    name: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      roleExists: false,
      permissions: [],
    };
  },
  methods: {
    ...mapActions("alerts", ["fireAlert"]),
    savePermissions(eventData) {
      axios
        .put(
          `https://remote.eospowersolutions.com/my-devices/api/roles/${encodeURI(
            this.name
          )}/permissions`,
          JSON.stringify(eventData)
        )
        .then(() => {
          this.fireAlert([
            this.$t("Success"),
            this.$t("user.permissionsChanged"),
            "success",
          ]);
          this.loadData();
        })
        .catch(() => {
          this.fireAlert([
            this.$t("Error"),
            this.$t("user.permissionsNotChanged"),
            "error",
          ]);
        });
    },
    deleteRole() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger m-1",
          cancelButton: "btn btn-primary m-1",
        },
        buttonsStyling: false,
      });
      var viewModel = this;
      swalWithBootstrapButtons
        .fire({
          title: this.$t("role.deleteRoleName", { name: this.name }) + "?",
          text: this.$t("role.deleteNote"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("role.yesDelete"),
          cancelButtonText: this.$t("role.noCancel"),
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/my-devices/api/roles/${encodeURI(viewModel.name)}`)
              .then(() => {
                this.fireAlert([
                  this.$t("Success"),
                  this.$t("role.RoleDeleted"),
                  "success",
                ]);
                this.$router.back();
              })
              .catch((err) => {
                this.fireAlert([
                  this.$t("Error"),
                  this.$t("role.RoleNotDeleted"),
                  "danger",
                ]);
                console.error(err);
              });
          }
        });
    },
    loadRole(name) {
      this.loading = true;
      axios
        .get(`/my-devices/api/roles/${name}/permissions`)
        .then((res) => {
          this.permissions = res.data;
          this.roleExists = true;
        })
        .catch((err) => {
          this.roleExists = false;
          console.error(err);
          this.fireAlert([
            this.$t("Error"),
            this.$t("role.errorLoading"),
            "danger",
          ]);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadRole(this.name);
  },
  watch: {
    name(newVal) {
      this.loadRole(newVal);
    },
  },
};
</script>

<style>
</style>